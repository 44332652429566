<template>
    <el-dialog width="600px" top="40px" title="Ladda upp fil" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false" @closed="resetModalState">
        <FileUploader @uploaded="dataUploaded" class="my-10" />
        <p class="text-center">
            {{ responseMessage }}
        </p>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="primary">Stäng</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            responseMessage: "",
            responseType: "",
        };
    },

    components: {
        FileUploader: () => import("../components/FileUploader"),
    },

    methods: {
        closeModal() {
            this.$emit("close");
        },
        dataUploaded(response) {
            this.responseMessage = response.message;
            this.responseType = response.type;
            this.$emit("refresh");
        },
        resetModalState() {
            this.responseMessage = "";
            this.responseType = "";
        },
    },
};
</script>
